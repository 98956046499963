import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { DateAdapter } from '@angular/material/core';
import { Component, ElementRef, Inject, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { sessionConfirmDialogComponent } from '@fuse/components/confirm-dialog-session/confirm-dialog-session.component';
import { FuseConfigService } from '@fuse/services/config.service';
import { APPCONFIG } from 'app/main/config';
import { HttpService } from 'app/main/services/http.service';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-delivery-documents',
    templateUrl: './delivery-documents.component.html?v=${APPCONFIG.cacheVersion}',
    styleUrls: ['./delivery-documents.component.scss?v=${APPCONFIG.cacheVersion}'],
    providers: [DatePipe]
})
export class DeliveryDocumentsComponent implements OnInit {
    readonly main_app_logo = APPCONFIG.main_app_logo;
    jobPkId: any;
    profileForm: any;
    shipmentId: string;
    isAdmin=false;
    jobData: any;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public route: ActivatedRoute,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public _matDialogRef: MatDialogRef<any>,
        public dialog: MatDialog,
        private http1: HttpService,
        private spinner: NgxSpinnerService,
        private _router: Router,
        private ng2ImgMax: Ng2ImgMaxService,
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder, private http: HttpClient,
        private cdr:ChangeDetectorRef,
        private datePipe: DatePipe,
        private dateAdapter: DateAdapter<Date>
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                }
            }
        };

        this.dateAdapter.setLocale('en-GB'); // Set date to dd/MM/yyyy

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /** On init **/
    ngOnInit(): void {
        this.shipmentId = this.route.snapshot.paramMap.get('shipmentId');
        this.shipmentId = this.shipmentId?this.shipmentId:'';
        this.onForm();
        if(this.isAdmin) {
            this.adminLogon();
        }
    }

    /** On destroy **/
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    /** ----------------------------------------------------------------------------------------------------- **/

    onForm() {
        var jobId = '';
        var postCode = '';

        try {
            jobId = localStorage.getItem("j");
            jobId = jobId?jobId:'';
            
            postCode = localStorage.getItem("p");
            postCode = (postCode && jobId)?postCode:'';

            if(postCode || jobId) {
                this.shipmentId = postCode?jobId:'';
            }
            localStorage.removeItem("j");
            localStorage.removeItem("p");

            this.isAdmin = (jobId && postCode)?true:false;
        }catch(err){
            console.error(err);
        }

        this.profileForm = new FormGroup({
            shipmentNo: new FormControl(this.shipmentId, Validators.required),
            postCode: new FormControl(postCode, Validators.required),
            jobPkId: new FormControl(jobId)
        })
    }

    adminLogon() {
        let body = JSON.stringify(this.profileForm.value);
        let token = JSON.parse(localStorage.getItem('Token'));
        this.logon('/deliveryDocuments/initForAdmin', body, token);
    }

    logon(url, body, token) {
        this.spinner.show();
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'idToken': token
            })
        };
        this.http.post(APPCONFIG.appUrl+url, body, httpOptions)
        .subscribe(
            data => {
                if (data) {
                    this.spinner.hide();
                }
                this.jobData = data;
                
                /*this.jobApiData['items'].forEach((element, i) => {
                    this.deliveryDetails.push({ "itemId": element.itemPrimaryId, "expectedQuantity": element.quantity, "actualQuantity": element.quantity, "reasonId": "" })
                });
                this.resetDeliveryPodDetails();
                if (this.job.data.jobStatus !== 'Assigned' && (!this.isAdmin && this.job.data.jobStatus == 'Unassigned')) {
                    this.title = `This job is ${this.job.data.jobStatus}`;
                    this.confirmDialogRefSession = this.dialog.open(sessionConfirmDialogComponent);
                    this.confirmDialogRefSession.componentInstance.confirmMessage = this.title;
                    this.confirmDialogRefSession.afterClosed().subscribe(result => {
                        if (result && this.job.data.jobStatus == 'Completed' && this.detailPage && this.nextEnabled) {
                            this.detailPage = false;
                            this.nextEnabled = false;
                            this.profileForm.reset();
                        }
                    });

                }else if(this.job.httpStatusCode == 200 || this.job.httpStatusCode == 201 || this.job.statusCode == 1) {
                    this.detailPage = true;
                    this.isValide = false;
                    this.detailPageSubmit = true;
                    this.postDataClear = false;
                    this.initPodDates();
                }*/

            },
            err => {
                let title = "";
                if (err.error.statusCode == 2 && err.error.httpStatusCode == 400) {
                    //this.confirmDialogRefSession = this.dialog.open(sessionConfirmDialogComponent);
                    //this.confirmDialogRefSession.componentInstance.confirmMessage = "Invalid Details";
                    this.spinner.hide();
                }
                else {
                    this.http1.errorHandler(err, title);
                } this
                this.spinner.hide();
            }
        );
    }
}

