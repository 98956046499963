import { Component, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpService } from '../../../app/main/services/http.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { APPCONFIG } from '../../../app/main/config';

@Component({
    selector: 'choose-file-dialog',
    templateUrl: './choose-file-dialog.component.html?v=${APPCONFIG.cacheVersion}',
    styleUrls: ['./choose-file-dialog.component.scss?v=${APPCONFIG.cacheVersion}'],
    providers: [HttpService]
})
export class ChooseFileDialogComponent {
  @ViewChild('chooseFileInput') chooseFileInputVar: ElementRef;
  errorMsg='';
  successMsg='';
  result;

  public title: string;
  public config = {msg:'', uploadUrl:'', closeAfterUploadSuccess:false, uploadFailureMsg:'', uploadSuccessMsg:''};
  public returnData = {upload:false, fileType:'', fileSrc:''};

  /**
   * Constructor
   *
   * @param {MatDialogRef<ChooseFileDialogComponent>} dialogRef
   */

  constructor(public dialog: MatDialog, private spinner: NgxSpinnerService, private cdr:ChangeDetectorRef, 
        private http: HttpClient, private http1: HttpService, 
        public dialogRef: MatDialogRef<ChooseFileDialogComponent>) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    //
  }
  closeDialog() {
      this.dialogRef.close(this.returnData);
  }

  readFile(event: Event): void {
    var fileType='';
    this.spinner.show();
    if (event.type == 'click') {
        this.spinner.hide();
        return;
    }
    if ((<HTMLInputElement>event.target).files && (<HTMLInputElement>event.target).files[0]) {
        const file = (<HTMLInputElement>event.target).files[0];
        fileType = (file.type=='application/pdf')?'pdf':'jpg';

        if (typeof (FileReader) !== 'undefined') {
            let reader = new FileReader();
            reader.onload = e => {
                this.uploadFile(reader.result, fileType);
            };
            reader.readAsDataURL(file);
        }
    }
  }

  uploadFile(fileSrc, fileType) {
    this.spinner.show();
    let body = JSON.stringify({podSrc:fileSrc, podFileType:fileType});
    const httpOptions = { headers: new HttpHeaders({'Content-Type':'application/json', 'idToken':JSON.parse(localStorage.getItem('Token'))}) };

    this.http.post(this.config.uploadUrl, body, httpOptions)
      .subscribe(data => {
        this.result = data;
        this.spinner.hide();
        if (this.result.httpStatusCode == 200 || this.result.httpStatusCode == 201) {
          this.returnData.fileType=fileType;
          this.returnData.fileSrc=fileSrc;
          this.returnData.upload=true;
          this.successMsg = this.config.uploadSuccessMsg?this.config.uploadSuccessMsg:'Uploaded successfully';
        }else {
          this.returnData.upload=false;
        }
        if(this.config.closeAfterUploadSuccess) {
          this.closeDialog(); //close this dialog
        }
      },
      err => {
        this.spinner.hide();
        this.errorMsg = this.config.uploadFailureMsg?this.config.uploadFailureMsg:'Failed to upload';
        this.chooseFileInputVar.nativeElement.value = "";
      });
  }

}
