function makeAppConfig() {
    let overrideWithUatUrl = false;
    let localhostUrl = overrideWithUatUrl?'https://hhtportal-test.hotpoint.co.uk':'http://localhost:8080';

    let hostname = window.location.hostname;
    let isLocal = hostname.startsWith('localhost')||hostname.startsWith('127.0.0.1');
    let hostUrl = isLocal?localhostUrl:(window.location.protocol+"//"+hostname);

    //console.log("isLocal:"+isLocal+", localhostUrl:"+localhostUrl+", hostname:"+hostname+", hostUrl:"+hostUrl);
    let AppConfig = {
        env:(isLocal || hostUrl.indexOf('test')!=-1)?"uat":"prod",
        brand: 'Whirlpool',
        appUrl: hostUrl+"/spring-web/v4",
        google_client_id: '105511446126-csjc40bt6cbkcmmqrb8els7p0anh6uat.apps.googleusercontent.com',
        validDomains:['whirlpool.com','persistent.com'],
        favicon:'https://storage.googleapis.com/hht-prod.appspot.com/img/beko/favicon_beko.png',
        main_app_logo:'https://storage.googleapis.com/hht-prod.appspot.com/img/beko/BekoEULogoBlueWithNoBg02.png',
        white_app_logo:'https://storage.googleapis.com/hht-prod.appspot.com/img/beko/BekoEULogoWhiteWithNoBg03.png',
        blue_app_logo:'https://storage.googleapis.com/hht-prod.appspot.com/img/beko/BekoEULogoBlueWithNoBg02.png',
        cacheVersion:34
    };
    return AppConfig; 
} 
export const APPCONFIG = makeAppConfig();